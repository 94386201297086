import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Style from "./index.module.css";
import { FaTimes } from "react-icons/fa";
import reffrealImage from "../../../Assets/Images/refferal.png";
import { PiCurrencyInrBold } from "react-icons/pi";
import RazorpayButton from '../../PaymentGateway/RazorpayButton/Razorpay';
import { TiTick } from "react-icons/ti";
import { UserContext } from '../../../Contexts/UserContext';
import instance from "../../../instance/AxiosInstance";

const ReferralModal = ({ handleRefer, setIsModalOpen, modalData }) => {
  console.log(modalData, "modal data");

  const [subcriptions, setSubscriptions] = useState([])
  const user = useContext(UserContext);
  const { User } = user;

  const isLoggined = localStorage.getItem('logged')
  console.log(isLoggined, "login check");


  const handleCloseModal = () => {
    localStorage.removeItem("hasSignedUp");
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    instance.get('/api/user/subscription_plans/get_live_subscription')
      .then((response) => {
        const resData = response.data;

        const sortedData = resData.sort((a, b) => {
          const priceA = parseFloat(a.monthly_pricing);
          const priceB = parseFloat(b.monthly_pricing);
          return priceA - priceB;
        });

        // Update state with sorted data
        setSubscriptions([...sortedData]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    // ad offer modal



    //reffeal moal commented becuase we gonna render xmas offer for short span of tym


    <div className={Style.modalOverlay} style={{background:"white"}}>
      <div className={Style.modalContainer}>
        <div className={Style.modalContent}>
          <div className={Style.closeIconWrapper}>
            <button
              className={Style.closeButton}
              onClick={handleCloseModal}
              aria-label="Close modal"
            >
              <FaTimes />
            </button>
          </div>
          <div>
            <img src={modalData?.poster?.aspectUrl} alt="reff" className={Style.refferimage} />
          </div>
          <div className={Style.modal_right} style={{paddingInline:"10px"}}>
            <h1>{modalData?.heading}</h1>
            <p>
              {modalData?.discription}
            </p>
            <div style={{ display: "flex", flexDirection: "column", gap: "12px", color: "white" }}>
              {
                modalData?.buttons.length >= 1 ? (
                  modalData.buttons.map((singlebtn, index) => {
                    console.log(singlebtn.label, "single btn");

                    return (
                      <a href={singlebtn.link} key={index} className={Style.referralButton} style={{ textDecoration: "none",minWidth:"100px" }}>{singlebtn.label}</a>
                    )
                  })
                ) : ""
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralModal;
