import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Style from "./Style.module.css";
import { FaTimes } from "react-icons/fa";
import reffrealImage from "../../../Assets/Images/refferal.png";
import { PiCurrencyInrBold } from "react-icons/pi";
import RazorpayButton from '../../PaymentGateway/RazorpayButton/Razorpay';
import { TiTick } from "react-icons/ti";
import { UserContext } from '../../../Contexts/UserContext';
import instance from "../../../instance/AxiosInstance";

const ReferralModal = ({popData}) => {
  

  const user = useContext(UserContext);
  const { User } = user;
  
  
  

  return (
    // ad offer modal
    // <div className={Style.modalOverlay}>
    //   <div className={Style.modalContainer}>
    //     <div className={Style.modalContent}>
    //       <div className={Style.modal_right}>
    //         <h1 style={{ width: "100%", display: "flex", justifyContent: "center", color: "red", fontSize: "30px" }}>Christmas Offer</h1>
    //         <div className={Style.row2} style={{ width: "100%", display: "flex", alignItems: "center" }}>
    //           <h1 style={{
    //             display: "flex", margin: "0 8px",
    //             color: "gray",
    //             fontSize: "40px", alignItems: "center",
    //           }}>
    //             <PiCurrencyInrBold />
    //             <span
    //               className="strike_line"
    //               style={{
    //                 textDecoration: "line-through",
    //                 margin: "0 8px",
    //                 color: "gray",
    //                 fontSize: "40px"
    //               }}>
    //               1200
    //             </span>
    //             <PiCurrencyInrBold />
    //             <span style={{ color: "#FF0000", fontWeight: "bold" }}>0</span>
    //           </h1>
    //           <span style={{ color: "#008000", fontSize: "25px" }}> / </span>
    //           <h4 style={{ color: "#008000", fontSize: "25px" }}>Month</h4>

    //         </div>
    //         <p style={{ paddingTop: "20px" }}>
    //           <span style={{ color: "red", fontWeight: "bold" }}>🎄 Christmas & Newyear Special Offer! 🎁</span> Subscribe now for ₹1200 and enjoy posting up to 8 ads with 10 images per ad and 60 days of validity. This amazing plan is <span style={{ color: "red", fontWeight: "bold" }}>absolutely FREE</span>  as our Christmas gift to you! Don’t miss this exclusive limited-time offer—sign up today and start posting!
    //         </p>
    //         <Link to="/subscribe">
    //           <div style={{ paddingTop: "20px" }}>
    //             {
    //               isLoggined ? (
    //                 <button className={Style.referralButton} onClick={handleRefer} style={{ width: "100%", marginTop: "20px" }}>
    //                   subscribe
    //                 </button>
    //               ) : (
    //                 <Link to="/registration_login">
    //                 <button className={Style.referralButton}  style={{ width: "100%", marginTop: "20px" }}>
    //                   Register Now
    //                 </button>
    //                 </Link>

    //               )
    //             }

    //           </div>

    //         </Link>
    //       </div>
    //     </div>
    //   </div>
    // </div>


    //reffeal moal commented becuase we gonna render xmas offer for short span of tym


    <div className={Style.modalOverlay}>
      <div className={Style.modalContainer}>
        <div className={Style.modalContent}>
          {/* <div className={Style.closeIconWrapper}>
            <button
              className={Style.closeButton}
              onClick={handleCloseModal}
              aria-label="Close modal"
            >
              <FaTimes />
            </button>
          </div> */}
          <div>
            <img src={popData?.poster?.aspectUrl} alt="reff" className={Style.refferimage} />
          </div>
          <div className={Style.modal_right}>
            <h1 className={Style.heading}>{popData?.heading}</h1>
            <p className={Style.discription}>
              {popData?.discription}
            </p>
            <div style={{display:"flex" , flexDirection:"column" , gap:"10px",}}>
            {
             popData?.buttons.length >= 1 ? (
              popData.buttons.map((singlebtn,index)=>{
                return(
                  <a href={singlebtn.link} key={index} className={Style.referralButton} style={{textDecoration:"none"}}>{singlebtn.label}</a>
                )
              })
            ):""
            }
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralModal;
