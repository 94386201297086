import React, { useState } from "react";
import Style from './Style.module.css'
import Header from "../../../Components/AdminComponents/Header/Header";
import Sidebar from "../../../Components/AdminComponents/Sidebar/Sidebar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import adminInstance from "../../../instance/AdminInstance";
import { Tooltip } from "@mui/material";

const AdOfferModal = ({ title }) => {
  const navigate = useNavigate();

  const [CurrentInput, SetCurrentInput] = useState({
    heading: "",
    discription: "",
    from: "",
    expire: "",
  });

  const [buttons, setButtons] = useState([
    {
      label: "",
      link: "",
    },
  ]);

  const [poster, setPoster] = useState(null);

  // Validation state
  const [errors, setErrors] = useState({});

  // Add new button group
  const addButtonGroup = (e) => {
    e.preventDefault();
    setButtons((prev) => [
      ...prev,
      {
        label: "",
        link: "",
      },
    ]);
  };

  // Update button group
  const updateButton = (index, field, value) => {
    const updatedButtons = [...buttons];
    updatedButtons[index][field] = value;
    setButtons(updatedButtons);
  };

  // Remove a button group
  const removeButton = (index) => {
    setButtons((prevButtons) => prevButtons.filter((_, i) => i !== index));
  };

  // Validate form fields
  const validateForm = () => {
    const newErrors = {};
    if (!CurrentInput.heading) newErrors.heading = "Offer heading is required.";
    if (!CurrentInput.discription)
      newErrors.discription = "Offer description is required.";
    // if (!CurrentInput.from) newErrors.from = "Offer start date is required.";
    // if (!CurrentInput.expire) newErrors.expire = "Offer expiry date is required.";
    if (!poster) newErrors.poster = "Offer poster is required.";

    // Validate buttons
    buttons.forEach((button, index) => {
      if (!button.label)
        newErrors[`buttonLabel${index}`] = "Button label is required.";
      if (!button.link)
        newErrors[`buttonLink${index}`] = "Button link is required.";
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill in all required fields.");
      return;
    }

    const formData = new FormData();
    formData.append("heading", CurrentInput.heading);
    formData.append("discription", CurrentInput.discription);
    formData.append("from", CurrentInput.from);
    formData.append("expire", CurrentInput.expire);
    formData.append("file", poster);
    formData.append("buttons", JSON.stringify(buttons));

    try {
      await adminInstance.post(
        "/api/super_admin/pop/create_pop",
        formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }
      );

      toast.success("Successfully Added");
      navigate("/admin/plans");

      // Reset form
      SetCurrentInput({
        heading: "",
        discription: "",
        from: "",
        expire: "",
      });
      setPoster(null);
      setButtons([
        {
          label: "",
          link: "",
        },
      ]);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  return (
    <div className={Style.new}>
      <Sidebar />
      <div className={Style.newContainer}>
        <Header />

        <div className={Style.top}>
          <h1>{title}</h1>
        </div>

        <div className={Style.center}>
          <div className={Style.right}>
            <form onSubmit={(e) => handleSubmit(e)}>

              <div className={Style.formInput}>
                <label>Offer Heading <span>*</span></label>
                <input
                  type="text"
                  placeholder="Document Name"
                  required
                  id='Documentname'
                  value={CurrentInput.heading}
                  onChange={(e) =>
                    SetCurrentInput({ ...CurrentInput, heading: e.target.value })
                  }

                />
              </div>

              <div className={Style.formproperty}>
                <label>Offer Description </label>
                <textarea
                  name="Document Description"
                  placeholder="More Informations"
                  value={CurrentInput.discription}
                  onChange={(e) =>
                    SetCurrentInput({
                      ...CurrentInput,
                      discription: e.target.value,
                    })
                  }

                ></textarea>
              </div>
              <div className={Style.formInput}>
                <label>Offer From Date</label>
                <input
                  type="date"
                  value={CurrentInput.from}
                  onChange={(e) =>
                    SetCurrentInput({ ...CurrentInput, from: e.target.value })
                  }
                />
                {errors.from && <p className={Style.error}>{errors.from}</p>}
              </div>
              <div className={Style.formInput}>
                <label>Offer Expire Date</label>
                <input
                  type="date"
                  value={CurrentInput.expire}
                  onChange={(e) =>
                    SetCurrentInput({ ...CurrentInput, expire: e.target.value })
                  }
                />
                {errors.expire && <p className={Style.error}>{errors.expire}</p>}
              </div>




              <div className={Style.formInput}>
                <h2>Button Group</h2>
                {buttons.map((button, index) => (
                  <div key={index} style={{ marginBottom: "20px" }}>
                    <input
                      type="text"
                      placeholder="Button label"
                      value={button.label}
                      onChange={(e) =>
                        updateButton(index, "label", e.target.value)

                      }

                    />
                    <input
                      type="text"
                      placeholder="Button link"
                      value={button.link}
                      onChange={(e) =>
                        updateButton(index, "link", e.target.value)
                      }
                      style={{ marginBlock: "10px" }}
                    />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        removeButton(index);
                      }}
                      style={{
                        backgroundColor: "red"
                        , color: "white", padding: "10px", borderRadius: "10px"
                      }}
                    >
                      Remove
                    </button>
                    {errors[`buttonLabel${index}`] && (
                      <p className={Style.error}>
                        {errors[`buttonLabel${index}`]}
                      </p>
                    )}
                    {errors[`buttonLink${index}`] && (
                      <p className={Style.error}>
                        {errors[`buttonLink${index}`]}
                      </p>
                    )}
                  </div>
                ))}
                <button onClick={addButtonGroup} className={Style.featurebtn} style={{
                  backgroundColor: "#046BD2"
                  , color: "white", padding: "10px", borderRadius: "10px"
                }}>Add Button Group</button>
              </div>
              <div className={Style.formInput}>
                <label>Upload Poster</label>
                <input
                  type="file"
                  onChange={(e) => setPoster(e.target.files[0])}
                />
                {errors.poster && <p className={Style.error}>{errors.poster}</p>}
              </div>
              <div className={Style.formBtn}>
                <Tooltip title="Check the data before Saving the data">
                  <button>Save</button>
                </Tooltip>
                <button onClick={() => { navigate('/admin/document') }}>Cancel</button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdOfferModal;
