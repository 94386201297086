import React, { useState } from 'react'
import Style from './Style.module.css'
import Header from '../../../Components/AdminComponents/Header/Header'
import Sidebar from '../../../Components/AdminComponents/Sidebar/Sidebar'
import DataTable from '../../../Components/AdminComponents/DataTable/DataTable'
import { Link, useNavigate } from 'react-router-dom'
import instance from '../../../instance/AxiosInstance'
import { useEffect } from 'react'
import adminInstance from '../../../instance/AdminInstance'
import OfferModal from "../../../Components/AdminComponents/OfferModal/OfferModal"
import { toast } from 'react-toastify'

const OfferDetails = () => {
    const [popData, setPopData] = useState()
    const[pop_id,setPop_id] = useState("")


    const fetchpopData= ()=>{
      instance.get('/api/super_admin/pop/get_pop')
      .then((response) => {
        setPopData(response.data);
        setPop_id(response.data._id)
      })
      .catch((error) => {
        console.log(error);
      });
    }

  useEffect(() => {
    fetchpopData()
  }, []);

  const handledeletPpop = ()=>{
   adminInstance.delete(`/api/super_admin/pop/delete_pop/${pop_id}`).then((res)=>{
     toast.success("Successfully Deleted");
    fetchpopData()

   }).catch((err)=>{
    toast.error("Something went Wrong")
    
   })
  }

  const boxStyle = {
    width: "500px",
    height: "100px",
    backgroundColor: "white",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Shadow styling
    borderRadius: "8px", // Optional for rounded corners
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px auto",
    color:"red"
  };

  return (
    <div className={Style.list} >
      <Sidebar />
      <div className={Style.listContainer} >
        <Header />
        <div style={{width:"100%", display:"flex", justifyContent:"space-between" , paddingBlock :"20px"}}>
        <h1 className='title' style={{color:"gray"}}> Preview Of Current Modal</h1>
        <div style={{display:"flex",flexDirection:"column",gap:"10px"}}>
        <Link to={`/admin/offer/form`} className={Style.link} >
         Update Offer Modal
        </Link> 
        <button onClick={handledeletPpop} className={Style.delete}>
         Remove Offer Modal
        </button> 
        </div>
        
        </div>
        {
          popData ? (
        <OfferModal popData={popData}/>
          ) :(
            <p style={boxStyle}>No pop modal exists currently. Please add one if needed.</p>
          )
        }
      </div>
    </div>
  )
}

export default OfferDetails

