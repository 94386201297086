import React, { useContext, useEffect, useState } from 'react';
import Style from './Style.module.css';
import { TiTick } from "react-icons/ti";
import { UserContext } from '../../../Contexts/UserContext';
import authInstance from '../../../instance/AuthInstance';
import { PiCurrencyInrBold } from "react-icons/pi";
import RazorpayButton from '../../PaymentGateway/RazorpayButton/Razorpay';
import xmastree from "../../../Assets/Images/xmastree.svg"

const Plancard = ({ item }) => {

    const user = useContext(UserContext);
    const { User } = user;


    const selectHandler = (e) => {
        e.preventDefault();
        authInstance
            .post('/api/user/check_out/stripe-checkout', { subscriptionPlanId: item._id, userId: User._id })
            .then((response) => {
                window.location.replace(response.data.url);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className={Style.item_wrap}>
            {item.plan_name === "Silver Plan" ? (
                <div className={`${Style.wrapper} ${Style.christmas_wrapper}`}>

                    <div className={Style.top}>
                        <div className={Style.row1}>
                            <h3 style={{ color: "#FF0000" }}>{item?.plan_name} <span style={{ fontSize: "10px", color: "black" }}>( christmas offer )</span></h3> {/* Christmas red color */}
                            {item?.popular && (
                                <h5 style={{ color: "#008000", backgroundColor: "#FFFACD", borderRadius: "8px", padding: "2px 8px" }}>
                                    Popular
                                </h5>
                            )}
                        </div>

                        {/* Christmas Pricing Section */}
                        <div className={Style.row2}>
                            <h1 style={{
                                display: "flex", margin: "0 8px",
                                color: "gray",
                                fontSize: "20px", alignItems: "center",
                            }}>
                                <PiCurrencyInrBold />
                                <span
                                    className="strike_line"
                                    style={{
                                        textDecoration: "line-through",
                                        margin: "0 8px",
                                        color: "gray",
                                        fontSize: "20px"
                                    }}>
                                    1200
                                </span>
                                <PiCurrencyInrBold />
                                <span style={{ color: "#FF0000", fontWeight: "bold" }}>0</span>
                            </h1>
                            <span style={{ color: "#008000" }}> / </span>
                            <h4 style={{ color: "#008000" }}>Month</h4>
                        </div>

                        {/* Discount Section */}
                        {item?.discount !== "" && (
                            <div className={Style.row3} style={{ color: "#008000", fontWeight: "bold", }}>
                                <p style={{ backgroundColor: "red" }}>
                                    {item?.discount}% Discount
                                </p>
                            </div>
                        )}

                        {item?.monthly_pricing >= 0 && (
                            <div className={Style.row4}>
                                {User.offerApplied ? (
                                    <div
                                        style={{
                                            padding: "4px 4px",
                                            color: "white",
                                            backgroundColor: "#FF6347",
                                            borderRadius: "8px",
                                            display: "flex",
                                            justifyContent: "center",
                                            width: "100%"


                                        }}>
                                        Already Applied!
                                    </div>
                                ) : (
                                    <RazorpayButton plan={item} paid={false} />
                                )}
                            </div>
                        )}


                    </div>

                    {/* Features Section */}
                    <div className={Style.bottom}>
                        <div className={Style.title}>
                            <h2 style={{ color: "#FF0000" }}>Features</h2>
                        </div>
                        <div className={Style.row}>
                            {item?.Features.map((feature, index) => {
                                return (
                                    <div className={Style.item} key={index}>
                                        <span style={{ color: "#008000" }}>
                                            <TiTick />
                                        </span>
                                        <p>{feature}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {/* Christmas Tree SVG */}
                    {/* <div className={Style.tree}>
                        <img
                            src={xmastree}
                            alt="Christmas Tree"
                            style={{ width: "80px", margin: "52px 15px", display: "block" }}
                        />
                    </div> */}
                </div>

            ) : (
                <div className={Style.wrapper}>
                    <div className={Style.top}>
                        <div className={Style.row1}>
                            <h3>{item?.plan_name}</h3>
                            {item?.popular && <h5>Popular</h5>}
                        </div>
                        <div className={Style.row2}>
                            <h1>
                                <PiCurrencyInrBold /> {item?.monthly_pricing}
                            </h1>
                            <span> / </span>
                            <h4>Month per user</h4>
                        </div>
                        {item?.discount !== "" && (
                            <div className={Style.row3}>
                                <p>{item?.discount}% Discount</p>
                            </div>
                        )}
                        {item?.monthly_pricing > 0 && (
                            <div className={Style.row4}>
                                <RazorpayButton plan={item} />
                            </div>
                        )}
                    </div>
                    <div className={Style.bottom}>
                        <div className={Style.title}>
                            <h2>Features</h2>
                        </div>
                        <div className={Style.row}>
                            {item?.Features.map((feature, index) => {
                                return (
                                    <div className={Style.item} key={index}>
                                        <span>
                                            <TiTick />
                                        </span>
                                        <p>{feature}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Plancard;
